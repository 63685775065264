$(function() {
  /* hamburger transition */
  function hamburgerToggle() {
    $('.hamburger-button').toggleClass('active');
    $('.hamburger-menu').toggleClass('active');
    $('.site-title').toggleClass('active');
    $('.content').toggleClass('active');
  }

  $('.hamburger-button').click(function() {
    hamburgerToggle();
  });

  $('.hamburger-cover').click(function() {
    hamburgerToggle();
  });

  /* slider */
  $('.slider').slick({
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: false,
    infinite: true,
    speed: 1000,
  });

  /* slider buttons */
  $('.slide-prev').click(function() {
    $('.slider').slick('slickPrev');
  });

  $('.slide-next').click(function() {
    $('.slider').slick('slickNext');
  });

  /* article banner */ 
  $('.article-item-background').eq(0).addClass('current');

  $('.slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
    $('.article-item-background.current').removeClass('current');
    $('.article-item-background').eq(nextSlide).addClass('current');
  });
});
